import { useEffect, useState } from "react";
import {
  handleUpdateBusiness,
  handleUpdateBusinessAmenities,
} from "../../../actions/business";
import { useData } from "../../../../../../hooks/useData";
import { toast } from "react-toastify";
import { getLanguageObject } from "../../../../../../utils/language";

const useBusinessInfo = ({ business, onBusinessUpdated }) => {
  const [amenities = { data: [] }, isLoading, hasError] = useData({
    endpoint: "/amenities",
    fetchOnce: true,
  });

  const [shouldSubmitInfo, toggleInfoSubmit] = useState(false);
  const [shouldSubmitAmenities, toggleAmenitiesSubmit] = useState(false);

  const [values, setValues] = useState({
    name: "",
    description: "",
    types: [],
    logo: "",
    amenities: [],
  });

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    types: "",
  });

  useEffect(() => {
    if (business) {
      setValues({
        name: business.name,
        description: business.description,
        types: business.types.map((type) => {
          return {
            label: type,
            value: type,
          };
        }),
        logo: business.logo,
        amenities: business.amenities.map(({ amenity }) => {
          return {
            label: amenity?.title,
            value: amenity?.id,
          };
        }),
      });
    }
  }, [business]);

  const validateForm = (values, setErrors) => {
    let hasError = false;
    const errors = {};

    if (!values.name) {
      errors.name =
        getLanguageObject().pages.business_profile.about.form_errors.name;
      toast.error(
        getLanguageObject().pages.business_profile.about.form_errors.name,
      );

      hasError = true;
    }

    if (!values.description) {
      errors.description =
        getLanguageObject().pages.business_profile.about.form_errors.description;
      toast.error(
        getLanguageObject().pages.business_profile.about.form_errors
          .description,
      );

      hasError = true;
    }

    if (values.types.length === 0) {
      errors.types =
        getLanguageObject().pages.business_profile.about.form_errors.type;
      toast.error(
        getLanguageObject().pages.business_profile.about.form_errors.type,
      );

      hasError = true;
    }

    setErrors(errors);
    return hasError;
  };

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSubmitInfo = (e) => {
    e.preventDefault();

    if (validateForm(values, setErrors)) {
      return null;
    }

    toggleInfoSubmit(false);

    const variables = {
      name: values.name,
      description: values.description,
      types: values.types.map((type) => type.value),
      logo: values.logo,
    };

    handleUpdateBusiness({ variables, onSuccess: onBusinessUpdated });
  };

  const handleSubmitAmenities = () => {
    toggleAmenitiesSubmit(false);

    const amenities = values.amenities.map((amenity) => amenity.value);

    const variables = {
      amenities,
    };

    handleUpdateBusinessAmenities({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  const types = [
    { value: "FITNESS", label: "FITNESS" },
    { value: "SPORT", label: "SPORT" },
    { value: "WELLNESS", label: "WELLNESS" },
    { value: "SPA", label: "SPA" },
    { value: "BEAUTY", label: "BEAUTY" },
  ];

  const formattedAmenities = amenities.data.map((amenity) => ({
    label: amenity.title,
    value: amenity.id,
  }));

  const businessLogoList = values?.logo
    ? [
        {
          url: values.logo,
        },
      ]
    : [];

  return {
    values,
    errors,
    types,
    handleChangeInput,
    businessLogoList,
    handleSubmitInfo,
    shouldSubmitInfo,
    toggleInfoSubmit,

    amenities: formattedAmenities,
    shouldSubmitAmenities,
    toggleAmenitiesSubmit,
    handleSubmitAmenities,
  };
};

export default useBusinessInfo;
