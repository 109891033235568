import {
  LANGUAGE_CONSTANTS,
  LOCAL_STORAGE_KEY_CONSTANTS,
} from "../constant/global";
import { LOCAL_STORAGE_KEYS } from "../../store/constants/local-storage.constants";
import {
  getLanguageFileInEn,
  getLanguageFileInTr,
} from "../helpers/language.helpers";

export const getLanguageObject = () => {
  const language = localStorage.getItem(
    LOCAL_STORAGE_KEY_CONSTANTS.FORBODY_BUSINESS_LANGUAGE_KEY,
  );

  const userDetailString = localStorage.getItem(
    LOCAL_STORAGE_KEYS.FOR_BODY_USER,
  );

  const appType = JSON.parse(userDetailString)?.user?.application;

  switch (language) {
    case LANGUAGE_CONSTANTS.EN:
      return getLanguageFileInEn(appType);
    case LANGUAGE_CONSTANTS.TR:
      return getLanguageFileInTr(appType);
    default:
      return getLanguageFileInEn(appType);
  }
};
