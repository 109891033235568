import React from "react";
import Logout from "../../layouts/nav/components/Logout";
import Select from "react-select";
import { getLanguageObject } from "../../utils/language";

const Form = ({
  formInputs,
  onChangeFormInput,
  onSubmitForm,
  values,
  errors,
}) => {
  return (
    <form onSubmit={onSubmitForm}>
      {formInputs.map((formInput) => (
        <div className="mb-3">
          <label className="mb-1">
            <strong>
              {formInput.name}
              {formInput.required ? (
                <span className={"required"}>*</span>
              ) : null}
            </strong>
          </label>
          {formInput.type === "select" ? (
            <Select
              placeholder={formInput.placeholder}
              options={formInput.options}
              isSearchable={false}
              defaultValue={formInput.defaultValue}
              value={values[formInput.field]}
              className="custom-react-select"
              onChange={(value) => onChangeFormInput(formInput.field, value)}
            />
          ) : (
            <input
              type={formInput.type}
              className="form-control"
              value={values[formInput.field]}
              onChange={(event) =>
                onChangeFormInput(formInput.field, event.target.value)
              }
              placeholder={formInput.placeholder}
              required={formInput.required}
            />
          )}
          {/* input small description */}
          {formInput.description && (
            <div className="mt-2">
              <small>{formInput.description}</small>
            </div>
          )}

          {errors[formInput.field] && (
            <div className="text-danger fs-12">{errors[formInput.field]}</div>
          )}
        </div>
      ))}
      <div className="text-center mt-4">
        <button type="submit" className="btn btn-primary btn-block">
          {getLanguageObject().pages.common.form.submit_button}
        </button>
      </div>

      <div className="text-center mt-4">
        <Logout />
      </div>
    </form>
  );
};

export default Form;
