import TR from "../translation/locales/tr/fitness.language.tr.json";
import EN from "../translation/locales/en/fitness.language.en.json";
import BEAUTY_EN from "../translation/locales/en/beauty.language.en.json";
import BEAUTY_TR from "../translation/locales/tr/beauty.language.tr.json";
import { APP_TYPE } from "../constant/global";

export const getLanguageFileInEn = (appType) => {
  if (appType === APP_TYPE.BEAUTY) {
    return BEAUTY_EN;
  }

  return EN;
};

export const getLanguageFileInTr = (appType) => {
  if (appType === APP_TYPE.BEAUTY) {
    return BEAUTY_TR;
  }

  return TR;
};
