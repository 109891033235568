import { useData } from "../../../../hooks/useData";
import React, { useMemo, useState } from "react";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import Empty from "../../../../components/empty/Empty";
import PackageLessonCard from "../components/PackageLessonCard";
import { deletePackage } from "../../../../../services/modules/PackageService";
import { getLanguageObject } from "../../../../utils/language";
import useAppType from "../../../../hooks/useAppType";

const defaultPackageLessonModalState = {
  visible: false,
  packageLessonId: null,
};

const usePackageLessons = () => {
  const [packageLessons = [], isLoading, hasError, getPackageLessons] = useData(
    {
      endpoint: "package",
    },
  );

  const refreshPackageLessons = () => {
    getPackageLessons();
  };

  const [packageLessonModalState, setPackageLessonModalState] = useState(
    defaultPackageLessonModalState,
  );

  const handleOpenPackageLessonModal = (packageLessonId = null) => {
    setPackageLessonModalState({ packageLessonId, visible: true });
  };

  const handleClosePackageLessonModal = () => {
    setPackageLessonModalState(defaultPackageLessonModalState);
  };

  const onSubmitPackageLesson = () => {};

  const packageLessonModalProps = {
    onClose: handleClosePackageLessonModal,
    onSubmit: onSubmitPackageLesson,
    showPackageLessonModal: packageLessonModalState.visible,
    packageLessonId: packageLessonModalState.packageLessonId,
    getPackageLessons: getPackageLessons,
  };

  const { appType } = useAppType();

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Error
          description={
            getLanguageObject().pages.package_lessons.form.error_message
          }
        />
      );
    }

    const isEmpty = !packageLessons.length;

    if (isEmpty) {
      return (
        <Empty
          description={
            getLanguageObject().pages.package_lessons.form.empty_message
          }
        />
      );
    }

    const handleDeletePackageLesson = (id) => {
      deletePackage(id).then((response) => {
        if (response.status === 200) {
          refreshPackageLessons();
        }
      });
    };

    return packageLessons.map((packageLesson, index) => (
      <PackageLessonCard
        appType={appType}
        packageLesson={packageLesson}
        key={index}
        handleOpenPackageLessonModal={() => {
          handleOpenPackageLessonModal(packageLesson.id);
        }}
        handleDeletePackageLesson={() =>
          handleDeletePackageLesson(packageLesson.id)
        }
      />
    ));
  }, [hasError, isLoading, packageLessons]);

  return {
    packageLessonModalProps,
    handleOpenPackageLessonModal,
    Content,
  };
};

export default usePackageLessons;
