import { useEffect, useState } from "react";
import {
  createPackage,
  getPackage,
  updatePackage,
} from "../../../../../../../../../services/modules/PackageService";
import { toast } from "react-toastify";
import { useData } from "../../../../../../../../hooks/useData";
import { APP_TYPE, LEVELS, PLATFORM } from "../../../../../../../../constant/global";
import { getLanguageObject } from "../../../../../../../../utils/language";
import useAppType from "../../../../../../../../hooks/useAppType";

const usePackageLessonForm = ({
  packageLessonId,
  showPackageLessonModal,
  onClose,
  getPackageLessons,
}) => {
  const [packageLesson] = useData({
    fetchOnce: false,
    endpoint: `package/${packageLessonId}`,
  });

  const { appType } = useAppType();

  const handleChangeEquipments = (inputValue) => {
    const equipments = inputValue.split(",");

    setValues({ ...values, equipments });
  };

  const handleSelectLevel = (level) => {
    setValues({ ...values, level });
  };

  const handleGetPackageLesson = () => {
    getPackage(packageLessonId).then((response) => {
      if (response.status === 200) {
        setValues(response.data);
      }
    });
  };

  const getFormValues = (packageLesson) => {
    return packageLesson
      ? {
          branchId: packageLesson.branchId,
          description: packageLesson.description,
          duration: packageLesson.duration,
          equipments: packageLesson.equipments,
          serviceCount: packageLesson.serviceCount,
          name: packageLesson.name,
          participantCount: packageLesson.participantCount,
          preparation: packageLesson.preparation,
          price: packageLesson.price,
          level: packageLesson.level || LEVELS.INTERMEDIATE,
          platform: packageLesson.platform,
        }
      : {
          branchId: "",
          equipments: [],
          name: "",
          description: "",
          preparation: "",
          duration: null,
          price: null,
          serviceCount: null,
          participantCount: null,
          level: LEVELS.INTERMEDIATE,
          platform: PLATFORM.IN_PERSON,
        };
  };

  const formValues = getFormValues(packageLesson);

  const [values, setValues] = useState(formValues);

  const shouldFetchPackageData = showPackageLessonModal && packageLessonId;

  useEffect(() => {
    if (shouldFetchPackageData) {
      handleGetPackageLesson();
      setValues(formValues);
    }
  }, [shouldFetchPackageData]);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleSelectBranch = (id) => {
    setValues({ ...values, branchId: id });
  };

  const handleSelectPlatform = (platform) => {
    setValues({ ...values, platform: platform });
  };

  const notifyError = (text) => {
    toast.error(`❌ ${text} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    const isNewPackage = !packageLessonId;
    e.preventDefault();

    const participantCount = appType === APP_TYPE.FITNESS ? parseInt(values.participantCount) : 1;

    const level = appType === APP_TYPE.FITNESS ? values.level : "INTERMEDIATE";

    const variables = {
      name: values.name,
      preparation: values.preparation,
      description: values.description,
      duration: parseInt(values.duration),
      price: parseInt(values.price),
      serviceCount: parseInt(values.serviceCount),
      equipments: values.equipments,
      branchId: values.branchId,
      platform: values.platform,
      level,
      participantCount,
    };

    if (isNewPackage) {
      createPackage(variables).then((response) => {
        if (response.error) {
          notifyError(response.message);
        } else {
          onClose();
          getPackageLessons();
        }
      });
    } else {
      updatePackage(packageLessonId, variables).then((response) => {
        if (response.status === 200) {
          onClose();
          getPackageLessons();
        } else {
          notifyError(response.message);
          onClose();
        }
      });
    }
  };

  const buttonLabel = getLanguageObject().pages.common.form.submit_button;

  return {
    handleSubmit,
    handleChangeInput,
    handleSelectBranch,
    handleSelectPlatform,
    values,
    buttonLabel,
    handleChangeEquipments,
    handleSelectLevel,
    isAddModalActive: !packageLessonId,
    appType,
  };
};

export default usePackageLessonForm;
