import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const useAppType = () => {
  const { appBusiness } = useContext(AppContext);

  const appType = appBusiness.application;

  return { appType };
};

export default useAppType;
