import React, { createContext, useState } from "react";
import EN from "../jsx/translation/locales/en/fitness.language.en.json";
import { LOCAL_STORAGE_KEY_CONSTANTS } from "../jsx/constant/global";

export const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(EN);

  const changeLanguage = () => {
    const language = localStorage.getItem(
      LOCAL_STORAGE_KEY_CONSTANTS.FORBODY_BUSINESS_LANGUAGE_KEY,
    );
    setLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
